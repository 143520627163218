import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import DoubleColumnText from '../components/common/DoubleColumnText';
import FooterMoutains from '../components/common/FooterMountains';
import Header from '../components/common/Header';
import MultipleTexts from '../components/common/MultipleTexts';
import PieChart from '../components/common/PieChart';
import Push from '../components/common/Push';
import TripleColumnText from '../components/common/TripleColumnText';
import VerticalLine from '../components/common/VerticalLine';
import FlexibleContent from '../components/FlexibleContent';
import LayoutCt from '../components/layout';
import SEO from '../components/SEO/SEO';
import colors from '../styles/colors.js';

const Layout = styled(LayoutCt)`
    .intro {
        z-index: 3;
    }
    .m-b {
        margin-bottom: 70px;
    }
    .el-bg {
        z-index: 3;
        &.one {
            grid-row: 9/10;
        }
        &.two {
            grid-row: 10/11;
        }
        .title-label {
            color: white;
        }
    }
    .img-bg {
        position: relative;
        grid-column: 1/4;
        grid-row: 9/11;
        width: 100%;
        z-index: 2;
        img {
            position: absolute;
            width: 100%;
            height: 90%;
            object-fit: cover;
        }
        .gradient {
            width: 100%;
            height: 91%;
            position: absolute;
            top: 0;
            background: linear-gradient(
                to bottom,
                ${colors.transparent_dark_blue},
                ${colors.dark_blue} 60%
            );
        }
    }
    @media screen and (min-width: 800px) {
        .m-b {
            margin-bottom: 130px;
        }
        .img-bg {
            grid-column: 1/15;
            height: 100%;
            img {
                height: 100%;
                object-fit: cover;
            }
            .gradient {
                height: 100%;
                background: linear-gradient(
                    to bottom,
                    ${colors.transparent_dark_blue},
                    ${colors.dark_blue} 90%
                );
            }
        }
    }
`;

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Asset Management" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageAsset_management {
                        double_column_text_1 {
                            label
                            position_image
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            text_left
                            text_right
                            title
                        }
                        double_column_text_2 {
                            label
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        double_column_text_3 {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        double_column_text_4 {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        double_column_text_5 {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        flexible_content {
                            acf_fc_layout
                            double_column_text {
                                images {
                                    image_desktop {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                    image_mobile {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                }
                                label
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                                position_image
                                text_left
                                text_right
                                title
                            }
                            key_numbers {
                                label
                                list_number {
                                    legend
                                    number
                                }
                            }
                            list_push {
                                array_push {
                                    title
                                }
                                label
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            multiple_texts {
                                contents {
                                    content
                                }
                                images {
                                    image_desktop {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                    image_mobile {
                                        filesize
                                        height
                                        mime_type
                                        title
                                        url
                                        width
                                    }
                                }
                                label
                            }
                            pie_chart {
                                parts {
                                    list_items {
                                        item
                                    }
                                    title
                                    value_max
                                    value_min
                                }
                            }
                            push {
                                label
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            text {
                                label
                                text
                                link {
                                    label
                                    page
                                    target
                                    type
                                    url
                                }
                            }
                            triple_column_text {
                                block_texts {
                                    text
                                    title
                                }
                                title
                            }
                            triple_title {
                                text
                                titles {
                                    title
                                }
                            }
                            vertical_steps {
                                label
                                text
                                steps {
                                    step
                                }
                            }
                        }
                        header {
                            subtitle
                            title
                            type
                        }
                        multiple_texts_1 {
                            contents {
                                content
                            }
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                        }
                        multiple_texts_2 {
                            label
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            contents {
                                content
                            }
                        }
                        multiple_texts_3 {
                            label
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            contents {
                                content
                            }
                        }
                        pie_chart {
                            parts {
                                list_items {
                                    item
                                }
                                title
                                value_max
                                value_min
                            }
                        }
                        push {
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        triple_column_text_1 {
                            title
                            block_texts {
                                text
                                title
                            }
                        }
                        triple_column_text_2 {
                            block_texts {
                                text
                                title
                            }
                            title
                        }
                    }
                }
            }
        }
    }
`;

const AssetManagement = ({ data, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    data = data.allWpPage.edges[0].node.acfPageAsset_management;

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header data={data.header} />
            <DoubleColumnText
                data={data.double_column_text_1}
                id="asset-allocation"
            />
            <PieChart data={data.pie_chart} />
            <MultipleTexts data={data.multiple_texts_1} />
            <DoubleColumnText
                data={data.double_column_text_2}
                id="in-house-strategies"
            />
            <TripleColumnText data={data.triple_column_text_1} />
            <DoubleColumnText
                data={data.double_column_text_3}
                id="private-debt"
            />
            <TripleColumnText
                data={data.triple_column_text_2}
                className="m-b"
            />
            <DoubleColumnText
                data={data.double_column_text_4}
                className="el-bg one"
                id="direct-investments"
            />
            <MultipleTexts data={data.multiple_texts_2} className="el-bg two" />
            <div className="img-bg">
                <picture>
                    <source
                        media="(min-width: 800px)"
                        srcSet="/images/assetmanagement/desktop/bg-asset.jpg"
                    />
                    <img
                        src="/images/assetmanagement/mobile/bg-asset.jpg"
                        alt=""
                    />
                </picture>
                <div className="gradient"></div>
            </div>
            <DoubleColumnText
                data={data.double_column_text_5}
                id="cryptocurrency-arbitrage-fund"
            />
            <MultipleTexts data={data.multiple_texts_3} />
            <FlexibleContent data={data} />
            <VerticalLine />
            <Push data={data.push} />
            <FooterMoutains />
        </Layout>
    );
};

export default AssetManagement;
